import { createUseStyles } from 'react-jss'
import { commonStyles } from '../../lib/common.styles'

export const useStyles = createUseStyles(theme => {
  const { container, primaryButton, secondaryButton } = commonStyles(theme)

  return {
    container: {
      ...container,
      animation: '$animateOpacity 0.5s ease-in'
    },

    wrapper: {
      height: 180,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: theme.white,
      background: 'black',
      boxShadow: 'inset 0px -4px 7px -7px white',
      animation: '$slideOpen 0.5s ease-out'
    },

    title: {
      fontSize: 24,
      margin: [0, 0, 10]
    },

    copy: {
      fontSize: 16,
      textAlign: 'left',
      margin: [0, 0, 20]
    },

    buttons: {
      margin: 0,
      width: '100%',
      maxWidth: 270,
      display: 'flex',
      justifyContent: 'space-between'
    },

    primaryButton: {
      ...primaryButton,
      margin: 0,
      fontSize: 14,
      padding: [10, 20]
    },

    secondaryButton: {
      ...secondaryButton,
      margin: 0,
      fontSize: 14,
      padding: [10, 20]
    },

    '@keyframes slideOpen': {
      from: {
        height: 0
      },

      to: {
        height: 180
      }
    },

    '@keyframes animateOpacity': {
      from: {
        opacity: 0
      },

      to: {
        opacity: 1
      }
    }
  }
})
