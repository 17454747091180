import React from 'react'
import { ThemeProvider } from 'react-jss'

import Banner from './banner'
import theme from '../../../../theme'
import AuthProvider from '../../../shared/hooks/use-auth'
import ApiContextProvider from '../../../shared/hooks/use-api-client'

const AbandonedSignupBanner = () => (
  <ThemeProvider theme={theme}>
    <ApiContextProvider>
      <AuthProvider>
        <Banner />
      </AuthProvider>
    </ApiContextProvider>
  </ThemeProvider>
)

export default AbandonedSignupBanner
