import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'

import { useAbandonedBanner } from '../../hooks/use-abandoned-banner'
import { useStyles } from './abandoned-signup-banner.styles'

/**
 * Renders the abandoned sign up banner
 */
const Banner = () => {
  const styles = useStyles()
  const location = useLocation()
  const { shouldRenderBanner, signupLink, onCancel } = useAbandonedBanner()

  return (
    shouldRenderBanner && (
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <h2 className={styles.title}>Welcome back!</h2>
          <p className={styles.copy}>
            Your account is created but you&apos;ll need to add payment details to
            finish adding a subscription.
          </p>

          <div className={styles.buttons}>
            <NavLink to={signupLink} className={styles.primaryButton}>
              Finish Sign Up
            </NavLink>

            <NavLink
              onClick={onCancel}
              to={location}
              className={styles.secondaryButton}
            >
              No thanks
            </NavLink>
          </div>
        </div>
      </div>
    )
  )
}

export default Banner
