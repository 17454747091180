import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import qs from 'query-string'

import { links, signupSequence } from '../../shared/subscription-constants'
import { useAuth } from '../../shared/hooks/use-auth'
import { useApiClient } from '../../shared/hooks/use-api-client'
import { signup } from '../../../lib/analytics/events'
import {
  getSignupState,
  clearSignupState,
  shouldShowAbandonedSignupBanner
} from '../lib/signup-state'

/**
 * Responsible for determining whether to render the sign up abandoned
 * banner on the site and handle user interaction with the banner.
 */
export const useAbandonedBanner = () => {
  const location = useLocation()
  const { voucher, plan } = getSignupState()
  const { isAuthenticated } = useAuth()
  const { doesUserHaveAnActiveSubscription } = useApiClient()

  const isInSignupModule = /^\/signup\//g.test(location.pathname)
  const isInSearch = /.+search=.*/g.test(location.search)

  const [userHasSubscription, setUserHasSubscription] = useState(true)
  const userAbandonedSignupRecently = shouldShowAbandonedSignupBanner()
  const [userHasDismissedBanner, setUserHasDismissedBanner] = useState(false)

  useEffect(() => {
    const loadServerData = async () => {
      try {
        const serverData = await doesUserHaveAnActiveSubscription()
        setUserHasSubscription(serverData)
      } catch (error) {
        // Swallow, worst case we don't render the banner.
      }
    }

    if (isAuthenticated) {
      loadServerData()
    }
  }, [isAuthenticated])

  const shouldRenderBanner = isAuthenticated &&
    !isInSearch &&
    !isInSignupModule &&
    !userHasSubscription &&
    !userHasDismissedBanner &&
    userAbandonedSignupRecently

  const signupLink = {
    ...location,
    pathname: links.payment,
    search: qs.stringify({
      plan,
      voucher
    }),
    state: {
      signup: signupSequence.abandon
    }
  }

  return {
    shouldRenderBanner,
    signupLink,
    onCancel: () => {
      clearSignupState()
      setUserHasDismissedBanner(true)
      signup.completeWithoutSubscription()
    }
  }
}
